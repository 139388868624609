import './Dashboard.scss';

import React from 'react';

import { Dashboard } from './components/Dashboard';
import { Header } from '../Layout/Header';
import { CurrentStoreName } from '../Common/Common/CurrentStoreName';

const DashboardPage = () => (
    <>
        <Header
            title="Översikt"
            subTitle={(
                <CurrentStoreName
                    prefix="| "
                    component="h2"
                    sx={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                    }}
                />
            )}
        />
        <div className='box'>
            <Dashboard />
        </div>
    </>
);

export default DashboardPage;
