import styled from '@emotion/styled';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import { Box, Typography } from '@mui/material';
import { css } from 'emotion';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import {
    Conversation,
    ConversationReadState,
    GetActiveConversationsQuery,
    OrderByDirection,
    useGetActiveConversationsQuery,
    useReadConversationsMutation,
} from 'src/generated/graphql';

import { useStore } from '../Common/context/store-context';
import { useDialog3 } from '../Common/custom-hooks/useDialog3';
import { usePaginationArguments } from '../Common/custom-hooks/usePaginationArguments';
import AppButton from '../Common/Form/AppButton';
import ServiceOrderDialog from '../ServiceOrder/components/Dialogs/ServiceOrderDialog';
import { Header } from '../Layout/Header';

type ConversationRowType = NonNullable<GetActiveConversationsQuery['activeConversations']>['items'][number];

const tableStyles = css`
    border: 2px solid #dfdfdf;
    border-radius: 5px;
    min-height: 100px;
    max-width: 1200px;
    margin: 0 auto;
`;

const Wrapper = styled.div`
    position: relative;
`;

const customStyles = {
    rows: {
        style: {
            cursor: 'pointer',
        },
    },
};

const ConversationStatus = ({ unreadMessages }: { unreadMessages: number }) => {
    return <Box sx={{
        backgroundColor: unreadMessages ? '#0288d1' : '#D3D3D3',
        borderRadius: '50%',
        height: '16px',
        width: '16px',
    }} />
}

const columns = [
    {
        name: 'Ordernummer',
        selector: 'order.id',
        sortable: false,
        cell: (row: ConversationRowType) => row.order?.orderNumber || '',
        grow: 1,
    },
    {
        name: 'Oläst',
        selector: 'unreadMessages',
        sortable: false,
        grow: 0,
        cell: (row: ConversationRowType) => <ConversationStatus unreadMessages={row.unreadMessages} />
    },
    {
        name: 'Inlämnad produkt',
        selector: 'bike',
        sortable: false,
        cell: (row: ConversationRowType) => {
            const { bikeType, customerBike } = row.order || {};
            const { manufacturer = '', description = '' } = customerBike || {};

            return `${bikeType} ${manufacturer}${description}`;
        },
        grow: 1,
    },
    {
        name: 'Meddelande',
        selector: 'message',
        style: {
            overflowWrap: 'break-word'
        },
        cell: (row: ConversationRowType) => {
            const [first] = row.smsRecords || [];

            const fullMessage = first?.message || '';
            const shortMessage = fullMessage.substr(0, 40);

            if (fullMessage.length > shortMessage.length) {
                return `${shortMessage} …`;
            }

            if (fullMessage !== '') {
                return fullMessage;
            }

            return '-';
        },
        sortable: false,
    },
    {
        name: 'Kundens namn',
        selector: 'order.customer.name',
        sortable: false,
    },
    {
        name: 'Telefonnummer',
        selector: 'toNumber',
        sortable: false,
    },
    {
        name: 'Inkommet datum',
        selector: 'createdAt',
        cell: (row: ConversationRowType) => {
            const [first] = row.smsRecords || [];

            if (!first) {
                return '-';
            }

            const date = DateTime.fromMillis(first?.createdAt);

            if (date.toRelativeCalendar() === 'today') {
                return date.toFormat('HH:mm');
            }

            return date.toISODate();
        },
        sortable: true,
    },
    {
        name: 'Bokningslista',
        selector: 'subStoreName',
        sortable: false,
        style: {
            overflowWrap: 'break-word',
        },
        cell: (row: ConversationRowType) => {
            const [ first ] = row.order?.subStores || [];

            return first?.name || 'Huvudlista';
        }
    },
];

const rowStyle = [
    {
        when: (row: ConversationRowType) => row.unreadMessages <= 0,
        style: {
            backgroundColor: '#FAFAFA',
            color: '#656565',
        },
    },
    {
        when: (row: ConversationRowType) => row.unreadMessages > 0,
        style: {
            color: '#000',
            fontSize: '16px !important',
            '*': {
                fontWeight: '500 !important',
            }
        },
    },
];

const CustomToolBar = ({ onSetRead, onSetUnread }: { onSetUnread: () => void; onSetRead: () => void; }) => (
    <div style={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        width: '100%',
    }}>
        <div style={{
            display: 'flex',
            gap: '20px',
        }}>
            <AppButton startIcon={<MarkChatReadOutlinedIcon />} onClick={onSetRead}>
                Markera som läst
            </AppButton>
            <AppButton startIcon={<MarkChatUnreadOutlinedIcon />} onClick={onSetUnread}>
                Markera som oläst
            </AppButton>
        </div>
    </div>
);

export function ConversationList() {
    const [storeId] = useStore();

    const [checked, setChecked] = useState<Conversation['id'][]>([]);
    const [pagination, setPagination] = usePaginationArguments({
        orderDir: OrderByDirection.Desc,
    });

    const { data, previousData, refetch } = useGetActiveConversationsQuery({
        variables: {
            pagination,
            filter: {
                storeId,
            }
        },
    });

    // Re-fetch list, lowered the interval to 15s since we already have CPU issues with database.
    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 15000);

        return () => clearInterval(interval);
    }, [refetch]);

    const smsRecords = data?.activeConversations.items || previousData?.activeConversations.items || [];

    const [ markReadMutation ] = useReadConversationsMutation();

    const markRead = async (ids?: number[]) => {
        await markReadMutation({
            variables: {
                ids: ids ?? checked,
                action: ConversationReadState.Read,
            },
            refetchQueries: ['GetActiveConversations'],
        });
    };

    const markUnread = async (ids?: number[]) => {
        await markReadMutation({
            variables: {
                ids: ids ?? checked,
                action: ConversationReadState.Unread,
            },
            refetchQueries: ['GetActiveConversations'],
        });
    }

    const onSetUnread = async () => {
        await markUnread();
    };

    const onSetRead = async () => {
        await markRead();
    };

    const { createDialog } = useDialog3();

    const showServiceOrder = async (clickedRow: ConversationRowType) => {
        await markRead([clickedRow.id]);

        createDialog((ref: any) => (
            <ServiceOrderDialog
                serviceOrderId={clickedRow.order?.id}
                dialogRef={ref}
            />
        ), { defaultIndex: 3 });
    };

    const changeSort = (col:  IDataTableColumn, sortDirection: 'desc' | 'asc') => {
        setPagination({
            ...pagination,
            orderDir: sortDirection === 'asc' ? OrderByDirection.Asc : OrderByDirection.Desc,
            orderBy: col.selector as string,
        });
    }

    return (
        <Wrapper>
            <Header title="Kommunikation med kund" />
            <div className={tableStyles}>
                <DataTable
                    pagination
                    pointerOnHover
                    subHeader={true}
                    subHeaderComponent={<CustomToolBar onSetUnread={onSetUnread} onSetRead={onSetRead} />}
                    columns={columns}
                    selectableRows
                    onSelectedRowsChange={({ selectedRows }) => {
                        setChecked(selectedRows.map((row) => row.id));
                    }}
                    conditionalRowStyles={rowStyle}
                    data={smsRecords}
                    defaultSortField={'serviceDate'}
                    onRowClicked={showServiceOrder}
                    paginationComponentOptions={{
                        rowsPerPageText: 'Poster per sida',
                        rangeSeparatorText: 'av',
                        selectAllRowsItemText: 'Alla',
                    }}
                    customStyles={customStyles}
                    paginationServer={true}
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[20, 50, 100]}
                    sortServer={true}
                    onSort={(col, sortDirection) => {
                        changeSort(col, sortDirection);
                    }}
                    paginationTotalRows={1}
                    onChangePage={(page) => {
                        setPagination({ ...pagination, page });
                    }}
                />
            </div>
        </Wrapper>
    );
}
