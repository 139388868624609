import 'react-datepicker/dist/react-datepicker.css';

import sv from 'date-fns/locale/sv';
import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';
import React, { forwardRef, useRef } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';

import TextInput from '../../../../Common/Form/TextInput';
import { useGetScheduleQuery } from '../../../../../generated/graphql';

registerLocale('sv', sv);

const availableStyle = css`
    background-color: green;
    color: white;
    border-radius: 0.3rem;
`;

const couldBeAvailableStyle = css`
    background-color: yellow;
    color: white;
    border-radius: 0.3rem;
`;

const notAvailableStyle = css`
    background-color: red;
    color: white;
    border-radius: 0.3rem;
`;

const standardHighlight = css`
    background-color: #216ba5;
    color: #fff;
`;

const datePickerWrapperStyle = css`
    width: 100%;
`;

const getFirstAvailableDate = (scheduleAvailabilityData = []) => {
    // Return null while data is not loaded so we can handle loading state
    if (isEmpty(scheduleAvailabilityData)) {
        return null;
    }

    const firstAvailableScheduleEntry = scheduleAvailabilityData.scheduleAvailableList.find(({ status }) => status === 'green' || status === 'yellow');

    // If there are no available date then fall back on today's date
    if (!firstAvailableScheduleEntry) {
        return new Date();
    }

    return new Date(firstAvailableScheduleEntry.date);
};

const getScheduleAvailability = (scheduleAvailabilityData = []) => {
    if (isEmpty(scheduleAvailabilityData)) {
        return [{ [standardHighlight]: new Date() }];
    }
    const statuses = {
        green: availableStyle,
        yellow: couldBeAvailableStyle,
        red: notAvailableStyle,
    };

    const statusForDates = scheduleAvailabilityData.scheduleAvailableList.reduce(
        (acc, { date, status }) => {
            const parsedDate = new Date(date);
            const currentStatus = statuses[status];
            const currentDatesForStyle = acc[currentStatus];
            return {
                ...acc,
                [currentStatus]: [
                    ...(currentDatesForStyle ? currentDatesForStyle : []),
                    parsedDate,
                ],
            };
        },
        {}
    );

    return Object.entries(statusForDates).map(([key, value]) => ({
        [key]: value,
    }));
};

const Calendar = ({ control, name, storeId, subStoreId, label, disabled }) => {
    const { data: scheduleData } = useGetScheduleQuery({
        fetchPolicy: 'no-cache',
        variables: { storeId, subStoreId, numberOfDays: 60 },
    });

    const ref = useRef(null);

    const highlightWithRanges = getScheduleAvailability(scheduleData);
    const firstAvailableDate = getFirstAvailableDate(scheduleData);

    // eslint-disable-next-line no-unused-vars
    const CustomInput = forwardRef((props, ref) => {
        return (
            <TextInput
                id={'datepicker-'+name}
                label={label}
                {...props}
            />
        );
    });

    return (
        <Controller
            name={name}
            control={control}
            render={({ onChange, value }) => {

                if (value === null && firstAvailableDate) {
                    setTimeout(() => onChange(firstAvailableDate));
                }

                const waitForDate = !value && !firstAvailableDate;

                return <ReactDatePicker
                    disabled={disabled || waitForDate}
                    locale="sv"
                    selected={value}
                    onChange={onChange}
                    highlightDates={highlightWithRanges}
                    dateFormat='yyyy-MM-dd'
                    customInput={<CustomInput />}
                    wrapperClassName={datePickerWrapperStyle}
                    // locale={locale}
                    ref={ref}
                    onKeyDown={(e) => { if (e.key === 'Tab') { setTimeout(() => ref.current.setOpen(false), 10 ) } }}
                />
            }}
        />
    );
};

export default Calendar;
