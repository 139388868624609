import Box from '@material-ui/core/Box';
import React from 'react';
import { Calendar, Event, EventProps } from 'react-big-calendar';
import { ScheduleAvailable, useGetScheduleQuery } from 'src/generated/graphql';
import { bigCalendarLocalizer, bigCalendarMessage } from 'src/modules/App/config/big-calendar';
import LoaderCircular, { LoaderWrapper } from 'src/modules/Common/Common/LoaderCircular';
import { useStore } from 'src/modules/Common/context/store-context';
import { useSubStore } from '../Common/context/sub-store-context';

interface ExtendedEvent extends Event {
    availability: ScheduleAvailable,
}

export function StoreSchedule() {
    const [storeId] = useStore();
    const { selectedSubStoreId: subStoreId } = useSubStore();

    const { data, refetch } = useGetScheduleQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            storeId,
            numberOfDays: daysInMonth(new Date()),
            subStoreId,
            start: startOfMonth(new Date())
        },
    });

    if (!data) {
        return <LoaderWrapper><LoaderCircular /></LoaderWrapper>;
    }

    const eventList: ExtendedEvent[] = data.scheduleAvailableList.map((day) => {
        return {
            start: new Date(day.date),
            end: new Date(day.date),
            allDay: true,
            availability: day,
        };
    });

    return (
        <Box padding="1">
            <Calendar
                localizer={bigCalendarLocalizer}
                messages={bigCalendarMessage}
                components={{
                    event: CustomEvent,
                }}
                culture={'sv'}
                events={eventList}
                style={{ height: 830 }}
                onNavigate={(newDate) => {
                    // I tried to fetch earlier, but reloading the list with earlier dates
                    // creates some very buggy behaviour
                    refetch({ start: startOfMonth(newDate), numberOfDays: daysInMonth(newDate) });
                }}
                views={['month']}
            />
        </Box>
    );
}

function CustomEvent(props: EventProps<ExtendedEvent>) {
    const availability = props.event.availability;

    const hourFormat = (m: number) => {
        const h = (m / 60 || 0)
        return new Intl.NumberFormat('sv-SE', { maximumSignificantDigits: 3 }).format(h) + ' h';
    };

    const classes = ['calendar-day', 'c-'+availability.status];

    if (!availability.scheduleTime && !availability.timeUsed) {
        classes.push('calendar-day-notime');
    }

    return (
        <div className={classes.join(' ')}>
            <div className='calendar-day-orders'>{availability.numberOfJobs}</div>
            <div className='calendar-day-time'>
                {hourFormat(availability.timeUsed)} / {hourFormat(availability.scheduleTime)}
            </div>
        </div>
    );
}

function startOfMonth(date: Date) {
    date.setDate(1);
    date.setHours(0, -date.getTimezoneOffset(), 0, 0);
    date.setMonth(date.getMonth());
    return date.toISOString();
}

function daysInMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();
}
