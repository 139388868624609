/**
 * @param {'stage'|'production'|'develop'|undefined} env
 */
const gtmIdFn = (env) => ({
    stage: 'GTM-56WWPSMN',
    production: 'GTM-NWB5VQXV',
    develop: undefined,
}[env]);

/**
 * Injects the Google Tag Manager script into the document.
 * @param {'stage'|'production'|'develop'|undefined} env
 */
export function injectGTM(env) {
    const gtmId = gtmIdFn(env);

    if (!gtmId) {
        return;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
    });

    // Add the script tag to the head
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}&l=dataLayer`;
    document.head.appendChild(script);

    // Add the noscript fallback
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
        <iframe 
            src="https://www.googletagmanager.com/ns.html?id=${gtmId}" 
            height="0"
            width="0"
            style="display:none;visibility:hidden"
        ></iframe>`;
    document.body.appendChild(noscript);
}
