import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import React from 'react';
import { useSyncTooEasyMutation } from 'src/generated/graphql';
import AppButton from 'src/modules/Common/Form/AppButton';

export function TooEasySync() {
    const [update, { loading }] = useSyncTooEasyMutation();

    const sync = () => {
        update().then();
    };

    return (
        <AppButton
            disabled={loading}
            icon={faCheckCircle}
            onClick={sync}
        >Uppdatera</AppButton>
    );
}
