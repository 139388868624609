import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FeatureToggleService } from "../../../services/Launchdarkly/services/FeatureToggleService";
import LoaderCircular from '../../Common/Common/LoaderCircular';
import { useStore } from '../../Common/context/store-context';
import { useUser } from "../../Common/context/user-context";
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import AppButton from '../../Common/Form/AppButton';
import AppTextInput from '../../Common/Form/AppTextInput';
import { MinutesInput } from '../inputs/Inputs';
import { useSubStore } from '../../Common/context/sub-store-context';
import { useSaveScheduleStandardMutation, useScheduleStandardQuery } from '../../../generated/graphql';

const SchedulesStandardForm = () => {
    const { register, handleSubmit, reset, getValues } = useForm();
    const [user] = useUser();
    const [storeId] = useStore();
    const { selectedSubStoreId: subStoreId } = useSubStore();
    const { enqueueSnackbar } = useSnackbar();
    const [enableTooEasyIntegration, setEnableTooEasyIntegration] = useState(null);

    const {
        data,
        loading,
        error,
        called,
    } = useScheduleStandardQuery({ variables: { storeId, subStoreId }, fetchPolicy: 'cache-and-network' });

    const [update, { loading: updating }] = useSaveScheduleStandardMutation();

    // @todo The launch darkly client needs to be initialized every time,
    //  refactor this so that it can be initialized when we have a user id and all stores.
    //  Refactor this as a provider
    const service = useMemo(
        async () => await FeatureToggleService.getClient({ key: `${user.username}`, custom: { storeIds: storeId } }),
        [user, storeId],
    );

    useEffect( () => {
        async function setEnableTooEasyIntegrationFn() {
            const client = await service;
            const result = await client.variation('SCHEDULE_ENABLE_TOOEASY_INTEGRATION', false);
            setEnableTooEasyIntegration(result);
        }
        setEnableTooEasyIntegrationFn();

    }, [service, setEnableTooEasyIntegration]);

    useEffect(() => {
        if (data) {
            reset(data.scheduleStandard);
        }
    }, [data, reset]);

    if (!called || loading) {
        return <LoaderCircular visible={true} size={10} />;
    }

    if (error) {
        return <ErrorMessage error={error} />;
    }

    const transformFormValues = (formValues) => {
        return {
            minutesPerJob: parseInt(formValues.minutesPerJob) || 0,
            monday: parseInt(formValues.monday) || 0,
            tuesday: parseInt(formValues.tuesday) || 0,
            wednesday: parseInt(formValues.wednesday) || 0,
            thursday: parseInt(formValues.thursday) || 0,
            friday: parseInt(formValues.friday) || 0,
            saturday: parseInt(formValues.saturday) || 0,
            sunday: parseInt(formValues.sunday) || 0,
            tooEasyId: formValues.tooEasyId || '',
        };
    };

    const onSubmit = async (formValues) => {
        const result = await update({
            variables: { storeId, subStoreId, formValues: transformFormValues(formValues) },
        });
        reset(result.data.saveScheduleStandard);
        enqueueSnackbar('Schemat uppdaterat!', { variant: 'success' });
    };

    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit)}
            >
                <Card>
                    <CardContent>
                        <Typography color='textSecondary'>
                            Minuter per jobb
                        </Typography>
                        <div className='schedule-form-fields'>
                            <MinutesInput
                                label='Minuter per job'
                                name='minutesPerJob'
                                register={register}
                                getValues={getValues}
                            />
                        </div>
                        <Typography color='textSecondary'>
                            Minuter per dag
                        </Typography>
                        <div className='schedule-form-fields'>
                            <MinutesInput
                                type='number'
                                label='Måndag'
                                name='monday'
                                register={register}
                                getValues={getValues}
                            />
                            <MinutesInput
                                type='number'
                                label='Tisdag'
                                name='tuesday'
                                register={register}
                                getValues={getValues}
                            />
                            <MinutesInput
                                type='number'
                                label='Onsdag'
                                name='wednesday'
                                register={register}
                                getValues={getValues}
                            />
                            <MinutesInput
                                type='number'
                                label='Torsdag'
                                name='thursday'
                                register={register}
                                getValues={getValues}
                            />
                            <MinutesInput
                                type='number'
                                label='Fredag'
                                name='friday'
                                register={register}
                                getValues={getValues}
                            />
                            <MinutesInput
                                type='number'
                                label='Lördag'
                                name='saturday'
                                register={register}
                                getValues={getValues}
                            />
                            <MinutesInput
                                type='number'
                                label='Söndag'
                                name='sunday'
                                register={register}
                                getValues={getValues}
                            />
                            <Typography color='textSecondary'>
                                { enableTooEasyIntegration ? "TooEasy integration" : "TooEasy integration - tillfälligt avstängd" }
                            </Typography>
                            <div className='schedule-form-fields'>
                                <AppTextInput
                                    label='TooEasy Id'
                                    name='tooEasyId'
                                    placeholder={'t.ex. 001'}
                                    inputRef={register}
                                    style={{ display: enableTooEasyIntegration ? null : 'none' }}
                                />
                            </div>
                            <AppButton
                                disabled={updating}
                                type='submit'
                                icon={faCheckCircle}
                            >
                                Spara
                            </AppButton>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>
    );
};

export default SchedulesStandardForm;
