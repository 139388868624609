import styled from '@emotion/styled';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faPortrait } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import debounce from '@material-ui/core/utils/debounce';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSubStore } from 'src/modules/Common/context/sub-store-context';
import AppCheckbox from 'src/modules/Common/Form/AppCheckbox';
import { isCompanyCustomer } from 'src/modules/Customer/helpers/company.helper';
import MenuDivider from 'src/modules/Layout/Menu/MenuDivider';
import ActiveDeliveryAddressInput from 'src/modules/ServiceOrder/components/Inputs/ActiveDeliveryAddressInput';

import { useGetCustomerByIdLazyQuery, useUpdateServiceOrderMutation } from '../../../../../../generated/graphql';
import LoaderCircular from '../../../../../Common/Common/LoaderCircular';
import { StoreContext, useStore } from '../../../../../Common/context/store-context';
import { UserContext } from '../../../../../Common/context/user-context';
import AppButton from '../../../../../Common/Form/AppButton';
import AppSelect from '../../../../../Common/Form/AppSelect';
import CurrencyInput from '../../../../../Common/Form/CurrencyInput';
import { FormStatus } from '../../../../../Common/Form/FormStatus';
import TextInput from '../../../../../Common/Form/TextInput';
import { useMechanics } from '../../../../hooks/useMechanics';
import { useOnChange } from '../../../../hooks/useOnChange';
import { transformToUpdateServiceOrderInput } from '../../../../utils/serviceOrderUtils';
import Calendar from '../../../Inputs/Calendar/Calendar';
import CustomerBikeInput from '../../../Inputs/CustomerBikeInput';
import CustomerInput from '../../../Inputs/CustomerInput';
import { DeleteButton } from './DeleteButton';
import { PrintButton } from './PrintButton';
import ServiceOrderHistoryTimeLine from './ServiceOrderHistoryTimeLine';
import { StatusBackButtons } from './StatusBackButtons';
import { StatusButtons } from './StatusButtons';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const VerticalDivider = styled.div`
    border-left: 2px dotted black;
`;

const ModalContent = styled.div`
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
    margin: 0 -24px;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
    background: #e9e9e9;
    margin: 20px -24px -24px;
    padding: 16px 24px;
`;

const ModalContentInner = styled.div`
    margin: 0 24px;
    display: flex;
    flex-direction: column;
`;

const defaultValues = (serviceOrder, currentSubStore) => {
    const [ subStore ] = serviceOrder?.subStores || [ currentSubStore ] || [];
    const subStoreId = subStore?.id;

    // We need initial values in some cases or else it will
    // switch from being uncontrolled components to controlled.
    return {
        ...(serviceOrder || {}),
        useAlternativeContact: serviceOrder?.useAlternativeContact ?? false,
        customerBikeId: serviceOrder?.customerBike?.id || null,
        serviceDate: serviceOrder?.serviceDate ? new Date(serviceOrder?.serviceDate) : null,
        subStoreId: subStoreId || 999999,
    };
};

const bikeTypes = ['Barn/junior', 'BMX', 'Elcykel', 'MTB', 'Lastcykel', 'LVG/gravel/CX', 'Special', 'Standard', 'Triathlon/tempo', 'Hybrid', 'Lös del', 'Vet ej']

export function ServiceOrderForm(props) {
    const { loading = false, serviceOrder, onSubmit, onDelete, debounceWait = 500 } = props;
    const { selectedSubStore, activeSubStores } = useSubStore();

    const [fetchCustomer, { data: { customer } = {}, refetch: refetchCustomer }] = useGetCustomerByIdLazyQuery({
        // Make sure that customer and bike list is refreshed
        // Had a problem when a bike was added to a customer by someone else and
        // bike list was not refreshed.
        fetchPolicy: 'cache-and-network',
        variables: { id: serviceOrder?.customerId },
    });

    useEffect(() => {
        // @todo Fetch company based on company id?
        if (serviceOrder?.customerId) {
            fetchCustomer({
                variables: { id: serviceOrder.customerId },
            });
        }
    }, [serviceOrder?.customerId, fetchCustomer]);

    const {
        handleSubmit,
        register,
        control,
        setValue,
        getValues,
        watch,
        errors,
    } = useForm({
        defaultValues: defaultValues(serviceOrder, selectedSubStore),
    });

    const [ loggedInUser ] = useContext(UserContext);
    const { sub: loggedInUserId } = loggedInUser.attributes;
    const [ activeStoreId ] = useStore();
    const { selectedSubStoreId: activeSubStoreId } = useSubStore()

    const { users } = useMechanics(activeStoreId);

    // Use the store of the store if unless creating new order
    const storeId = serviceOrder?.store?.id ?? activeStoreId;

    const [updateServiceOrder, { loading: updating }] = useUpdateServiceOrderMutation();

    const patch = async (serviceOrderInput) => {
        return await updateServiceOrder({
            variables: {
                serviceOrderInput,
                id: serviceOrder.id,
            },
        });
    };

    const currentMechanic = getValues('serviceByUserId') || serviceOrder?.serviceBy?.id;

    const onAssignToMe = async () => {
        setValue('serviceByUserId', loggedInUserId);
    };

    const invoiceLabelMaxLength = calculateInvoiceLabelMaxLength(getCurrentBike(customer, getValues('customerBikeId')));

    const [invoiceLabelError, setInvoiceLabelError] = React.useState(false);

    const submitAction = async (formData) => {
        setInvoiceLabelError(false);
        setInvoiceLabelError(false);
        if (isFunction(onSubmit)) {
            onSubmit(formData);
            return;
        }

        const serviceOrderInput = transformToUpdateServiceOrderInput(formData, loggedInUser, customer);
        await patch(serviceOrderInput);
    };
    
    const handleUpdate = async () => {
        if (getValues('invoiceLabel') !== undefined && getValues('invoiceLabel').length > invoiceLabelMaxLength) {
            setInvoiceLabelError(`Fakturamärkning får ej överskrida ${invoiceLabelMaxLength} tecken.`);
            return;
        }

        if (serviceOrder?.id) {
            await handleSubmit(submitAction)();
        }
    };

    const handleInputChange = debounce(handleUpdate, debounceWait);

    useOnChange(watch, 'serviceDate', handleUpdate);
    useOnChange(watch, 'serviceByUserId', handleUpdate);
    useOnChange(watch, 'subStoreId', handleUpdate);
    useOnChange(watch, 'bikeType', handleUpdate);

    const onBikeCreate = async (bike) => {
        // Inject the new bike on the customer list
        customer.bikes.push(bike);
        // Re-fetch bikes from server
        await refetchCustomer();

        // Set the form values
        setValue('customerBikeId', bike.id);
        setValue('description', `${bike.manufacturer || ''} ${bike.description || ''}`);

        await handleUpdate();
    };

    const onBikeChange = async (bikeId) => {
        const bike = getCurrentBike(customer, bikeId) ?? {};
    
        if (invoiceLabelMaxLength < getValues('invoiceLabel')?.length) {
            setInvoiceLabelError(`Fakturamärkning får ej överskrida ${calculateInvoiceLabelMaxLength(bike)} tecken.`);
            return;
        }
        if (!isEmpty(bike)) {
            setValue('description', `${bike.manufacturer} ${bike.description}`);
        }
    
        await handleUpdate();
    };

    const onCustomerChange = async (customer) => {
        if (customer?.company?.id) {
            setValue('companyId', customer.company.id);
        }

        if (customer?.id) {
            setValue('customerId', customer?.id);
            await fetchCustomer({ variables: { id: customer?.id } });
        }
        await handleUpdate();
    };

    const finished = serviceOrder?.status === 'closed';

    return (
        <Wrapper className="service-order-form">
            <ModalContent>
                {!serviceOrder?.id && <LoaderCircular visible={loading || updating} size={50} />}
                <input
                    type='hidden'
                    ref={register}
                    name='name'
                    value={customer?.name || ''}
                />
                <ModalContentInner>
                    <ServiceOrderHistoryTimeLine serviceOrder={serviceOrder} />
                    <div>
                        <StatusBackButtons serviceOrder={serviceOrder} onChange={(status) => patch({ status })} />
                    </div>
                </ModalContentInner>
                <VerticalDivider />
                <ModalContentInner>
                    <h3>
                        {serviceOrder?.orderNumber || ''}
                        {serviceOrder?.orderNumber && ' - '}
                        {serviceOrder?.store.name || ''}
                    </h3>
                    <div>
                        <div>
                            <Grid container spacing={3} style={{ marginTop: 40 }}>
                                <Grid item xs={6}>
                                    <CustomerInput
                                        onChange={onCustomerChange}
                                        customer={customer}
                                        register={register}
                                        disabled={finished}
                                    />
                                </Grid>
                                {isCompanyCustomer(customer) && (
                                    <>
                                        <Grid item xs={6} />
                                        <Grid item xs={6}>
                                            <ActiveDeliveryAddressInput
                                                control={control}
                                                customer={customer}
                                                onChange={handleInputChange}
                                                disabled={finished}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={6}>
                                    <AppSelect
                                        control={control}
                                        name="subStoreId"
                                        label="Bokningslista"
                                        disabled={finished}
                                        id="subStoreId"
                                    >
                                        <MenuItem key={0} value={999999}>Huvudlista</MenuItem>
                                        {activeSubStores.map((subStore) => (
                                            <MenuItem key={subStore.id} value={subStore.id}>{subStore.name}</MenuItem>
                                        ))}
                                    </AppSelect>
                                </Grid>
                                <Grid item xs={6}>
                                    <AppSelect
                                        // @todo: do we need validation for these fields?
                                        control={control}
                                        name="bikeType"
                                        label="Cykeltyp"
                                        defaultValue={serviceOrder?.bikeType || '-'}
                                        disabled={finished}
                                        rules={{
                                            validate: (value) => {
                                                return value !== undefined && value !== '-';
                                            },
                                        }}
                                        formErrors={errors}
                                    >
                                        <MenuItem value="-"><span style={{ color: '#727272' }}>Välj en cykeltyp</span></MenuItem>
                                        {bikeTypes.map((bike, index) => (
                                            <MenuItem key={index} value={bike}>
                                                {bike}
                                            </MenuItem>
                                        ))}
                                    </AppSelect>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        id="descriptionInput"
                                        label="Inlämnad produkt"
                                        name="description"
                                        register={register}
                                        defaultValue={serviceOrder?.description}
                                        validation={{ required: true }}
                                        onChange={handleInputChange}
                                        errors={errors}
                                        disabled={finished}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomerBikeInput
                                        bikes={customer?.bikes || []}
                                        customerId={customer?.id || null}
                                        companyId={customer?.company?.id || null}
                                        control={control}
                                        onBikeCreate={onBikeCreate}
                                        onBikeChange={onBikeChange}
                                        disabled={finished}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CurrencyInput
                                        label="Maxkostnad"
                                        name="maxCost"
                                        register={register}
                                        value={serviceOrder?.maxCost}
                                        onChange={handleInputChange}
                                        disabled={finished}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Calendar
                                        label="Planerad dag"
                                        name="serviceDate"
                                        control={control}
                                        storeId={storeId}
                                        subStoreId={activeSubStoreId}
                                        disabled={finished}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        id="receivedByInput"
                                        label="Mottagen av"
                                        name="receivedBy"
                                        register={register}
                                        validation={{ required: true }}
                                        onChange={handleInputChange}
                                        errors={errors}
                                        disabled={finished}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AppSelect
                                        control={control}
                                        name="serviceByUserId"
                                        label="Mekaniker"
                                        defaultValue={serviceOrder?.serviceBy?.id || ''}
                                        disabled={finished}
                                    >
                                        {users.map((user) => (
                                            <MenuItem key={user.id} value={user.id}>
                                                {user.name}
                                            </MenuItem>
                                        ))}
                                    </AppSelect>
                                </Grid>
                                <Grid item xs={6} />
                                <Grid item xs={6}>
                                    <TextInput
                                        id="customerCommentText"
                                        label="Kommentar från kund"
                                        name="customerComment"
                                        register={register}
                                        multiline
                                        disabled={finished}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        id="internalCommentText"
                                        label="Interna noteringar"
                                        name="internalComment"
                                        register={register}
                                        multiline
                                        disabled={finished}
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                {isCompanyCustomer(customer) ? (
                                    <>
                                        <Grid item xs={6}>
                                            <TextInput
                                                id="outlined-multiline-static"
                                                multiline
                                                label="Fakturamärkning"
                                                name="invoiceLabel"
                                                variant="outlined"
                                                fullWidth={true}
                                                register={register}
                                                disabled={finished}
                                                onChange={handleInputChange}
                                            />
                                            {invoiceLabelError ? <span style={{ color: 'red' }}>{invoiceLabelError}({watch('invoiceLabel').length}/{invoiceLabelMaxLength})</span> : ""}
                                        </Grid>
                                        {invoiceLabelError ? <span style={{ color: 'red' }}>{invoiceLabelError}({watch('invoiceLabel').length}/{invoiceLabelMaxLength})</span> : ""}
                                        <Grid item xs={6}>
                                            <TextInput
                                                id="outlined-multiline-static"
                                                multiline
                                                label="Fakturareferens"
                                                name="invoiceRef"
                                                variant="outlined"
                                                fullWidth={true}
                                                register={register}
                                                disabled={finished}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <MenuDivider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <AppCheckbox
                                                label="Använd referens för kommunikation"
                                                name="useAlternativeContact"
                                                control={control}
                                                disabled={finished}
                                                onChangedCallback={handleInputChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextInput
                                                id="alternativeContactPhone"
                                                label="Referens Telefonnummer"
                                                name="alternativeContactPhone"
                                                register={register}
                                                multiline
                                                disabled={finished}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    </>) : null
                                }
                            </Grid>
                        </div>
                    </div>
                    <FormStatus errors={errors} loading={loading || updating} />
                </ModalContentInner>
            </ModalContent>
            <ModalFooter container justify="flex-end">
                {serviceOrder?.id ? (
                    <>
                        <div className="button-wrapper button-wrapper-no-margin">
                            <StatusButtons serviceOrder={serviceOrder} onChange={(status) => patch({ status })} />
                            {loggedInUserId === currentMechanic || (
                                <AppButton
                                    onClick={onAssignToMe}
                                    variant="contained"
                                    disabled={finished}
                                    startIcon={<FontAwesomeIcon icon={faPortrait} />}
                                >
                                    Tilldela mig
                                </AppButton>
                            )}
                        </div>
                        <div className="button-wrapper-right button-wrapper-no-margin">
                            <DeleteButton disabled={finished} serviceOrder={serviceOrder} onDelete={onDelete} />
                            <PrintButton serviceOrder={serviceOrder} disabled={loading || updating} />
                        </div>
                    </>
                ) : (
                    <Grid container justify="flex-end">
                        <Grid item>
                            <AppButton
                                disabled={loading || updating}
                                type="button"
                                onClick={handleSubmit(submitAction)}
                                startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                            >
                                Spara
                            </AppButton>
                        </Grid>
                    </Grid>
                )}
            </ModalFooter>
        </Wrapper>
    );
}

const getCurrentBike = (customer, bikeId) => {
    return customer?.bikes?.find((bike) => bike.id === bikeId) ?? undefined
};

const calculateInvoiceLabelMaxLength = (selectedBike) => {
    const bikesDataLength = (selectedBike ? (selectedBike.tag?.length) : 0);

    // 999 is the length because it should not take over 1024 characters in the target system
    // And we need a safety margin of 24 characters so we set it to 1000
    // Then we need to account for the spaces in the target InoiceLabel that is between fields
    // we set it to 999 because we have 1 spaces in the target field
    // so in HeadsOrderService.ts(In the backend) it's formatted like `${order?.customerBike?.tag} ${order.invoiceLabel}`
    return 999 - bikesDataLength;
};

export default ServiceOrderForm;
