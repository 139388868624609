import './index.scss';

import { ApolloProvider } from '@apollo/react-hooks';
import CssBaseline from '@material-ui/core/CssBaseline';
import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { EnvironmentBanner } from './modules/App/EnvironmentBanner';
import Login from './modules/Login/Login';
import ConfigService from './services/Config/ConfigService';
import client from './services/GraphQL/client';
import { SentryService } from './services/SentryService/SentryService';
import { injectGTM } from './gtm';

async function init() {
    SentryService.initiate();

    let config = await ConfigService.getConfig();
    let amplifyConfig = {
        aws_cognito_identity_pool_id: config.cognitoIdentityPoolId,
        aws_cognito_region: config.cognitoRegion,
        aws_user_pools_id: config.cognitoUserPoolId,
        aws_user_pools_web_client_id: config.cognitoUserPoolClientId,
    };

    Amplify.configure(amplifyConfig);

    console.log(
        `Running as ${config.appEnv}. Started at ${config.appTimeStart}`
    );

    injectGTM(config.appEnv);

    ReactDOM.render(
        <>
            <CssBaseline />
            <BrowserRouter>
                <ApolloProvider client={client}>
                    <Login />
                    <EnvironmentBanner env={config.appEnv} />
                </ApolloProvider>
            </BrowserRouter>
        </>,
        document.getElementById('root')
    );
}

init();
