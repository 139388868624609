import React, { useEffect, useRef, useState } from 'react';
import { useSubStore } from '../../Common/context/sub-store-context';
import { useStore } from '../../Common/context/store-context';
import {
    useGetNewMessagesCountLazyQuery,
} from '../../../generated/graphql';


export const UnreadMessages = () => {
    const [ storeId ] = useStore();

    const [ count, setCount ] = useState(0);

    const timerRef = useRef<NodeJS.Timer | undefined>(undefined);

    const [ fetch ] = useGetNewMessagesCountLazyQuery({
        onCompleted: (data) => {
            setCount(data.newMessagesCount)
        },
        fetchPolicy: 'network-only',
    })

    // @todo Fetch number of unread messages

    useEffect(() => {
        const handleGetMessageCount = () => {
            fetch({
                variables: {
                    filter: {
                        storeId,
                    }
                },
            });
        }

        handleGetMessageCount();

        timerRef.current = setInterval(() => handleGetMessageCount(), 30000);

        return () => {
            clearInterval(timerRef.current);
        }
    }, [fetch, timerRef, storeId]);

    if (!count) {
        return null;
    }

    return <div key={count}>{count}</div>;
}
