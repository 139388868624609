import { Stack, StackProps, Typography } from '@mui/material';
import React from 'react';

type HeaderProps = { title: string, subTitle?: Element } & StackProps;

export const Header = ({ sx, title, subTitle, ...props }: HeaderProps) => (
    <Stack
        className="header"
        direction="row"
        sx={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '5px',
            gap: 0.8,
            ...sx,
        }}
        {...props}
    >
        <Typography
            component="h1"
            sx={{
                fontSize: '18px',
                fontWeight: 'bold',
            }}
        >{title}</Typography>
        {subTitle}
    </Stack>
);
