import './UserAdministration.scss';

import { faFont, faLockOpen, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { SystemUser } from 'src/generated/graphql';
import { useConfirmDialog } from 'src/modules/Common/Common/ConfirmDialog';
import { useStore } from 'src/modules/Common/context/store-context';
import { useUser } from 'src/modules/Common/context/user-context';
import { useDialog3 } from 'src/modules/Common/custom-hooks/useDialog3';
import AppButton from 'src/modules/Common/Form/AppButton';
import AppTableButtonLarge from 'src/modules/Common/Tables/AppTableButtonLarge';
import { AddUserToStoreDialog } from 'src/modules/UserAdministration/components/AddUserToStoreDialog/AddUserToStoreDialog';
import { CreateUserDialog } from 'src/modules/UserAdministration/components/CreateUserDialog/CreateUserDialog';
import { EditPasswordDialog } from 'src/modules/UserAdministration/components/EditPasswordDialog/EditPasswordDialog';
import { EditUserDialog } from 'src/modules/UserAdministration/components/EditUserDialog/EditUserDialog';
import { UserTable } from 'src/modules/UserAdministration/components/UserTable/UserTable';
import { useRemoveUserFromStoreMutation } from 'src/modules/UserAdministration/hooks/useUserMutations';
import { Header } from '../Layout/Header';

export const UserStoreAdministrationPage = () => {
    const [user] = useUser();
    const [storeId] = useStore()!;
    const table = useRef<any>({});

    const { createDialog } = useDialog3();
    const confirmDialog = useConfirmDialog();
    const { enqueueSnackbar } = useSnackbar();
    const [callRemoveUser, { loading }] = useRemoveUserFromStoreMutation();

    const openCreateUserDialog = () => {
        const ref = createDialog((ref: any) => (
            <CreateUserDialog
                dialogRef={ref}
                storeId={storeId}
            />
        ));

        ref.onClose.push(table.current.refetch);
    };

    const openAddUserToStoreDialog = () => {
        const ref = createDialog((ref: any) => (
            <AddUserToStoreDialog
                dialogRef={ref}
                storeId={storeId}
            />
        ));
        ref.onClose.push(table.current.refetch);
    };

    const openEditUserDialog = (row: SystemUser) => {
        const ref = createDialog((ref: any) => (
            <EditUserDialog
                dialogRef={ref}
                user={row}
            />
        ));
        ref.onClose.push(table.current.refetch);
    };

    const openEditPasswordDialog = (row: SystemUser) => {
        const ref = createDialog((ref: any) => (
            <EditPasswordDialog
                dialogRef={ref}
                user={row}
            />
        ));
        ref.onClose.push(table.current.refetch);
    };

    const removeUserFromStore = async (row: SystemUser) => {
        await callRemoveUser({
            variables: {
                userId: row.id,
                storeId: storeId,
            },
        });

        enqueueSnackbar('Användaren borttagen', { variant: 'warning' });
        table.current.refetch();
    };

    const showRemoveUserDialog = (row: SystemUser) => {
        confirmDialog({
            content: <>Är du säker på att du vill ta bort <b>{row.name}</b> från butiken?</>,
            buttons: [
                { label: 'Avbryt', variant: 'outlined' },
                { label: 'Radera', action: () => removeUserFromStore(row) },
            ],
        });
    };

    return (
        <div>
            <Header title="Användare" />
            <div className="box">
                <Box p={2} pt={0}>
                    <div className="button-wrapper">
                        <AppButton
                            icon={faPlus}
                            onClick={openCreateUserDialog}
                        >
                            Ny användare
                        </AppButton>
                        <AppButton
                            icon={faPlus}
                            onClick={openAddUserToStoreDialog}
                        >
                            Lägg till befintlig användare
                        </AppButton>
                    </div>
                </Box>
                <UserTable
                    loading={loading}
                    innerRef={table}
                    storeId={storeId}
                    actions={(row: SystemUser) => {
                        const isMe = user.username === row.id;
                        return (
                            <div style={{ textAlign: 'center', display: 'inline-block' }}>
                                <Grid container>
                                    <Grid item>
                                        <AppTableButtonLarge
                                            icon={faFont}
                                            onClick={() => openEditUserDialog(row)}
                                            label="Namn"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AppTableButtonLarge
                                            icon={faLockOpen}
                                            onClick={() => openEditPasswordDialog(row)}
                                            label="Lösenord"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AppTableButtonLarge
                                            icon={faTimes}
                                            disabled={isMe}
                                            onClick={() => showRemoveUserDialog(row)}
                                            label="Ta bort"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default UserStoreAdministrationPage;
