import React from 'react';

import { useStore } from '../../../Common/context/store-context';
import { useDialog2 } from '../../../Common/custom-hooks/useDialog2';
import AllOrders from './AllOrders';
import { Header } from '../../../Layout/Header';

const OrdersPage = () => {
    const { createDialog } = useDialog2();
    const [storeId] = useStore();

    if (!storeId) {
        return null;
    }

    return (
        <>
            <Header title="Serviceorder" />
            <div className='box'>
                <AllOrders createDialog={createDialog} storeId={storeId} key={storeId} />
            </div>
        </>
    );
};

export default OrdersPage;
