import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useStoreMetadataQuery, useUpdateStoreMetadataMutation } from '../../../generated/graphql';
import LoaderCircular from '../../Common/Common/LoaderCircular';
import { useStore } from '../../Common/context/store-context';
import { useUser } from '../../Common/context/user-context';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import AppButton from '../../Common/Form/AppButton';
import AppCheckbox from '../../Common/Form/AppCheckbox';
import { hasUserGroupAccess } from '../../Common/helpers/auth';
import { twentyFourHourTimePattern } from "../../Common/helpers/time";
import TimeInput from '../../StoreAdministration/inputs/TimeInput';

const StoreMetadataForm = () => {
    // Initialize form and set default values
    const { control, register, handleSubmit, reset, getValues } = useForm({
        defaultValues: {
            bookBeforeTime: '11:00',
            printerOffsetLeft: 0,
        },
    });

    const [ user ] = useUser();
    const [ storeId ] = useStore();
    const { enqueueSnackbar } = useSnackbar();

    // Communicate with the GraphQl API
    const { data, loading, error, called } = useStoreMetadataQuery({ variables: { storeId } });
    const [ update, { loading: updating } ] = useUpdateStoreMetadataMutation();

    // Override default values with loaded values
    useEffect(() => {
        if (data) reset(data.storeMetadata);
    }, [data, reset]);

    // Handle loading and error states
    if (!called || loading) return <LoaderCircular visible={true} size={10} />;
    if (error) return <ErrorMessage error={error} />;

    const transformFormValues = (formValues) => {
        return {
            id: data.id || null,
            storeId: storeId,
            bookBeforeTime: formValues.bookBeforeTime,
            isOnlineBookingActive: formValues.isOnlineBookingActive,
            printerOffsetLeft: 1,
        };
    };

    const onSubmit = async (formValues) => {
        await update({
            variables: { storeId, formValues: transformFormValues(formValues) },
            refetchQueries: ['StoreMetadata']
        });

        enqueueSnackbar('Inställningarna har uppdaterats!', { variant: 'success' });
    };

    return (
        <div className='store-metadata-container'>
            <div className="store-metadata-form">
                <Typography color='textSecondary'>
                    Hantera inställningar för onlinebokning
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <AppCheckbox
                        label='Aktivera onlinebokning för butiken.'
                        name='isOnlineBookingActive'
                        inputRef={register}
                        control={control}
                        disabled={!hasUserGroupAccess(user, 'Admins')}
                    />

                    <Typography color='textSecondary'>
                        I nedan fält fyller du i det senaste klockslaget som det ska vara möjligt att boka en service online samma dag.
                    </Typography>
                    <TimeInput
                        id='bookBeforeTime'
                        label='Tid, format 11:00'
                        name='bookBeforeTime'
                        register={register}
                        getValues={getValues}
                        inputProps={{ 'pattern': twentyFourHourTimePattern, 'required' : true }}
                    />
                    <AppButton
                        disabled={updating}
                        type='submit'
                        icon={faCheckCircle}
                    >
                        Spara
                    </AppButton>
                </form>
            </div>
        </div>
    );
};

export default StoreMetadataForm;
