import { useMutation } from '@apollo/client';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Tab, TabPanel } from 'react-tabs';

import { useStore } from '../../Common/context/store-context';
import { Dialog } from '../../Common/custom-hooks/useDialog2';
import ErrorMessage from '../../Common/ErrorMessage/ErrorMessage';
import AppButton from '../../Common/Form/AppButton';
import TextInput from '../../Common/Form/TextInput';
import { datePattern } from '../../Common/helpers/time';
import { DateInput,MinutesInput } from '../inputs/Inputs';
import { useSubStore } from '../../Common/context/sub-store-context';
import {
    useCreateScheduleDeviationMutation,
    useDeleteScheduleDeviationMutation,
    useUpdateScheduleDeviationMutation,
} from '../../../generated/graphql';

const ScheduleDeviationDialog = ({ dialogRef, data }) => {
    const {
        register,
        handleSubmit,
        reset,
        clearErrors,
        errors,
        getValues,
    } = useForm();
    const [storeId] = useStore();
    const { selectedSubStoreId: subStoreId } = useSubStore();

    const isCreate = !data.id;

    const onCompleted = () => {
        dialogRef.close(true);
    }

    const [updateScheduleDeviation, { loading: updating, error: updateError }] = useCreateScheduleDeviationMutation({
        errorPolicy: 'all',
        onCompleted
    });

    const [createScheduleDeviation, { loading: creating, error: createError }] = useUpdateScheduleDeviationMutation({
        errorPolicy: 'all',
        onCompleted,
    });

    const [deleteScheduleDeviation] = useDeleteScheduleDeviationMutation({
        errorPolicy: 'all',
        onCompleted,
    });

    const onUpdateOrCreate = isCreate ? createScheduleDeviation : updateScheduleDeviation;
    const loading = updating || creating;
    const error = updateError || createError;

    useEffect(() => {
        if (!isCreate) {
            reset({
                ...data,
            });
        }
    }, [isCreate, reset, data]);

    const transformFormValues = (formValues) => {
        clearErrors();

        return {
            storeId,
            date: formValues.date,
            timeAvailable: parseInt(formValues.timeAvailable) || 0,
            note: formValues.note || '',
            subStoreId,
        };
    };

    const onSubmit = async (formValues) => {
        const values = transformFormValues(formValues);
        if (values) {
            await onUpdateOrCreate({ variables: { id: data.id, formValues: values } });
        }
    };

    const deleteTime = async () => {
        await deleteScheduleDeviation({ variables: { id: data.id } })
    }

    return (
        <Dialog dialogRef={dialogRef}>
            <Tab>Avvikelse</Tab>
            <TabPanel>
                <ErrorMessage error={error} />
                <div style={{ minWidth: 500 }} id='deviatingDatesForm'>
                    <form
                        className='schedule-form-fields'
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ marginTop: 20 }}
                    >
                        <TextInput
                            id='note'
                            label='Beskrivning'
                            name='note'
                            register={register}
                        />
                        <DateInput
                            id='date'
                            label='Datum'
                            name='date'
                            register={register}
                            getValues={getValues}
                            inputProps={{ 'pattern': datePattern }}
                            formatGuide='yyyy-mm-dd'
                        />
                        {errors.date && <p>{errors.date.message}</p>}
                        <MinutesInput
                            id='timeAvailable'
                            label='Minuter tillgängligt'
                            name='timeAvailable'
                            register={register}
                            getValues={getValues}
                            inputProps={{ 'pattern': '^\\d+$' }}
                        />
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <AppButton
                                disabled={loading}
                                type='submit'
                                startIcon={<FontAwesomeIcon icon={faCheckCircle} />}
                            >
                                {isCreate ? 'Lägg till' : 'Uppdatera'}
                            </AppButton>
                            {
                                data?.id ? (<AppButton
                                    disabled={loading}
                                    type='button'
                                    onClick={deleteTime}
                                    startIcon={<FontAwesomeIcon icon={faTimesCircle} />}
                                >
                                    Ta bort
                                </AppButton>) : null
                            }
                        </Box>
                    </form>
                </div>
            </TabPanel>
        </Dialog>
    );
};

export default ScheduleDeviationDialog;
